import { RouteRecordRaw } from 'vue-router';
import { TrigrammeRetourEnum } from '@/enum/trigrammeRetour.enum';

const section = 'parametre';
const parametreAdminRoutes: RouteRecordRaw[] = [
    {
        path: '/admin/' + section,
        component: () => import('@/composants/structures/default/template-page-admin.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: section,
                component: () => import('@/domaine/administration/admin/parametre-admin-template.vue'),
            }
        ],
    },
];

export default parametreAdminRoutes;
