const enum TypeApiEnum {
    CATALOGUES = 'catalogues',
    COMMANDE = 'commande',
    COMPTE_COMPTABLE = 'compte_comptables',
    COMPTE_COMPTABLE_LISTE_FICHES_CLIENTS = 'compte_comptables_liste_fiches_clients',
    COMPTE_COMPTABLE_FICHE_CLIENT = 'compte_comptables_fiche_client',
    ETABLISSEMENT = 'etablissements',
    PANIER = 'panier',
    LIGNE_COMMANDE = 'ligne_commande',
    PIECE = 'piece',
    RETOUR = 'retour',
    RETOUR_LISTE = 'liste_retours',
    RETOUR_HISTORIQUE = 'retours_historique',
    CONSIGNES_ORDONNEES = 'consignes_ordonnees',
    RETOUR_GARANTIE_PSA = 'retour_garantie_psas',
    MOTIF_RETOUR = 'motif_retours',
    FACTURE = 'facture',
    AVOIR = 'avoir',
    TYPE_COMMANDE = 'type_commandes',
    MARQUE_VEHICULES = 'marque_vehicules',
    MODELE_VEHICULES = 'modele_vehicules',
    FORMULAIRE_REFERENCE_IDENTIFIANT = 'formulaire_reference_identifiant',
    FORMULAIRE_IAM = 'formulaire_iam',
    MEA_ACCUEIL = 'm_e_a_page_accueils',
    UTILISATEUR_COURANT = 'utilisateurs_courant',
    UTILISATEURS = 'utilisateurs',
    COMPTE_COMPTABLE_AUTOCOMPLETE = 'comptes-comptables-autocomplete',
    OBJECTIFS_CC = 'objectifs_cc',
    PNEU_FORMULAIRE_RECHERCHE = 'formulaire_recherche',
    PNEU_LISTE = 'pneus',
    TELEVENTES = 'televentes',
    MESSAGE_ACCUEIL = 'messages_accueil',
    LIGNE_BON_LIVRAISON = 'ligne_bon_livraisons',
    SECTEURS = 'secteur_geographiques',
    PLAQUE_PAR_DOMAINE = 'plaque_par_domaine',
    LIGNE_BON_LIVRAISONS_AUTOCOMPLETE_ADMIN = 'ligne_bon_livraisons_autocomplete_admin',
    STATISTIQUES_AGENTS = 'statistiques_agents',
    PLAN_TRANSPORT = 'plan_transport',
    MANQUANT_MOTIF_INDISPOS = 'manquant_motif_indispos',
}

export default TypeApiEnum;
