import { RouteRecordRaw } from 'vue-router';
import { TrigrammeRetourEnum } from '@/enum/trigrammeRetour.enum';

const section = 'manquants';
const manquantsAdminRoutes: RouteRecordRaw[] = [
    {
        path: '/admin/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: section + '-admin-toutes-les-demandes',
                redirect: {
                    name: section + '-toutes-les-demandes',
                    params: { trigramme: TrigrammeRetourEnum.TOUTES_LES_DEMANDES },
                },
                children: [
                    {
                        path: ':trigramme?',
                        name: section + '-toutes-les-demandes',
                        component: () => import('@/domaine/commandes/admin/liste-manquants/liste/liste-manquants.vue'),
                    }
                ],
            }
        ],
    },
];

export default manquantsAdminRoutes;
